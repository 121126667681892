import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Section } from "./Page";

const imaageProps = {
  height: 200,
  width: 200,
};

const BadgesSection = () => {
  return (
    <Section className="grid grid-cols-3 gap-8 sm:gap-6 items-center">
      <div className="text-center">
        <StaticImage {...imaageProps} transformOptions={{fit: "inside"}} src="../images/about/Featured_on_Badge_uk.png" alt="Featured on bride book" />
      </div>
      <div className="text-center">
        <StaticImage {...imaageProps} transformOptions={{fit: "inside"}} src="../images/about/LGBTQBadgeWeddingBusinessSchool.png" alt="Featured on bride book bagdge" />
      </div>
      {/* <div className="text-center">
        <StaticImage {...imaageProps} transformOptions={{fit: "inside"}} src="../images/about/WBC Badge.jpg" alt="Wedding business club badge" />
      </div> */}
      <div className="text-center">
        <StaticImage src="../images/about/weddingawards-badges-regionalfinalist-1b.jpg" alt="The 2021 Wedding Industry Awards regional finalist badge" />
      </div>
    </Section>
  );
};

export {
  BadgesSection,
};
