import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { BadgesSection } from "../components/BadgesSection";
import { Copy } from "../components/Copy";
import { Layout } from "../components/Layout";
import { Container, Heading, Section } from "../components/Page";
import { PageSeo } from "../components/Seo";
import { useSiteMetadata } from "../hooks/SiteMetadataQuery";

const weddingImageProps = {
  height: 600,
  width: 600,
};

const AboutPage = () => {
  const { getAQuoteUrl } = useSiteMetadata();
  return (
    <Layout>
      <PageSeo tabTitleSegments={["About"]} title="About" />
      <Container>
        <div className="mx-auto max-w-wide">
          <Heading>About</Heading>
          <StaticImage className="md:ml-6 mb-6 md:w-[400px] md:float-right" src="../images/about/Me.jpg" />
          <Copy className="md:col-span-2">
            <p>Hello!</p>
            <p>
              I'm Kimberley, an Illustrator from Norwich, Norfolk.
              I have a BA(hons) degree in Illustration.
              I set up KGH Illustrates in January 2020 with the aim to provide illustrated wedding invitations for couples who want something different to swirly font or just letter press.
              My style is watercolour illustrations inspired by nature.
              Fun fact about me; I love painting flowers, but I can't have them in my house. Too smelly!
            </p>
            <p>
              Prior to setting up my business, I was a High School Art Teacher.
              Unfortunately, I had to leave the profession when I was diagnosed with Endometriosis and M.E. as I found it too taxing on my health.
              Luckily, I had a plan to set up KGH Illustrates after organising my own wedding and designing all of our stationery!
              Take a look at some of the photos from my wedding <a href="#wedding-photos">below</a>.
              I have a one year old son called Ivar and a Ragdoll cat Called Winston (but we call him Winnie).
            </p>
            <p>
              Along with wedding stationery, I also offer a commission service for custom pet portraits, family portraits and people portraits in general!
            </p>
            <p>
              If you would like to use me for any of my services, be it wedding invitations, on the day stationery or if you want a commissioned portrait, please get in touch with me!
              I'd be happy to talk you through my process and help you with your ideas!
            </p>
            <ul>
              <li>For wedding enquires fill in the quote form <a rel="noreferrer" target="_blank" href={getAQuoteUrl}>here</a>.</li>
              <li>For portrait enquires fill in the form on the <Link to="/portraits#form">portraits page</Link>.</li>
              <li>For general enquires fill in the form on the <Link to="/contact">contact page</Link>.</li>
            </ul>
          </Copy>
        </div>
        <Section>
          <div id="wedding-photos" className="kgh-grid my-16 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-836.jpg" alt="Photo of the table plan from my wedding" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-1234.jpg" alt="A wedding photo of me on my husband's back" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-853.jpg" alt="A wedding photo of the tipi venue" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-1250.jpg" alt="A wedding photo of the tipi venue" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-839.jpg" alt="A wedding photo of a decoration inside the tipi venue" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-827.jpg" alt="A wedding photo of our cake" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-567.jpg" alt="A wedding photo of me and my husband with two alpacas" />
            <StaticImage {...weddingImageProps} src="../images/about/Kim&amp;Connel-843.jpg" alt="A wedding photo of our place names" />
          </div>
        </Section>
        <BadgesSection />
      </Container>
    </Layout>
  );
};

export default AboutPage;
